import * as React from "react"
import { Helmet } from "react-helmet"
import Layout from "../../components/layout"
import Cv from "../../components/Cv/Cv"

const CvPage = props => {
  const { slug, pageContext = {} } = props

  const { title, summary } = pageContext
  const titleTemplate = "%s | X-Profiles"

  return (
    <Layout>
      <Helmet title={title} titleTemplate={titleTemplate}>
        <meta name="description" content={summary} />
      </Helmet>
      <Cv slug={slug} />
    </Layout>
  )
}

export default CvPage
